import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component : () => import('../views/About.vue')
  },
  {
    path: '/about/#Intro',
    name: 'Intro',
    component : () => import('../views/About.vue')
  },
  {
    path: '/about/#AboutHistory',
    name: 'AboutHistory',
    component : () => import('../views/About.vue')
  },
  {
    path: '/about/#AboutGlory',
    name: 'AboutGlory',
    component : () => import('../views/About.vue')
  },
  {
    path: '/product/:ptid/:psid',
    name: 'product',
    component: () => import('../views/product.vue'),
    props: true
  },
  {
    path: '/product/:ptid/:psid/:pdid',
    name: 'productitem',
    component: () => import('../views/product.vue'),
    props: true
  },
  {
    path: '/professional',
    name: 'Professional',
    component: () => import('../views/Professional.vue')
  },
  {
    path: '/professional/#Process',
    name: 'Process',
    component: () => import('../views/Professional.vue')
  },
  {
    path: '/professional/#Promise',
    name: 'Promise',
    component: () => import('../views/Professional.vue')
  },
  {
    path: '/professional/#Quality',
    name: 'Quality',
    component: () => import('../views/Professional.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/service/#ServiceCenter',
    name: 'ServiceCenter',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/service/#ServiceOurService',
    name: 'ServiceOurService',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/service/#ServiceLocation',
    name: 'ServiceLocation',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/service/#ServiceRemoteControl',
    name: 'ServiceRemoteControl',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/service/#ServiceExample',
    name: 'ServiceExample',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/service/#ServiceProductReponsibility',
    name: 'ServiceProductReponsibility',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/resource',
    name: 'Resource',
    component: () => import('../views/Resource.vue')
  },
  {
    path: '/resource/#resourceHumanVacancies',
    name: 'ResourceHumanVacancies',
    component: () => import('../views/Resource.vue')
  },
  {
    path: '/resource/#resourceHumanResources',
    name: 'ResourceHumanResources',
    component: () => import('../views/Resource.vue')
  },
  {
    path: '/resource/#resourceContactUs',
    name: 'ResourceContactUs',
    component: () => import('../views/Resource.vue')
  },
  {
    path: '/invest/:l1/:l2',
    name: 'Invest',
    component: () => import('../views/Invest.vue'),
    props: true
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/Report.vue')
  }, 
]

const router = new VueRouter({
  routes,
})

export default router
