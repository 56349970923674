import axios from 'axios'

// https://www.elevator.com.tw
// hongwei-tw.test123.tw

let url = window.location.host
console.log('window.location.host:', url)
if (url == "localhost:8086") url = "www.elevator.com.tw"
const base = 'https://' + url + '/tw/api'
console.log('baseURL:', base)

const apiConfig = axios.create({
    baseURL: base
}) 

export default apiConfig
