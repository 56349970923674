import $ from 'jquery' 


$('.index__banner').owlCarousel({
    loop:true,
    margin:0,
    autoHeight: true,
    autoplay:false,
    autoplayTimeout:4000,
    autoplayHoverPause:true,
    smartSpeed: 1000,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        800:{
            items:1
        },
        1000:{
            items:1
        }
    }
}) ; 


$('.dropdown-toggle').dropdown()

$(document).ready(function() {
    $(".has-submenu ul").hide();
  $(".has-submenu").click(function() {
    $(this).children("ul").toggle();
  });
});


$(document).ready(function() {
  $(".mobile_menu_icon").on('click',function() {
    $(this).toggleClass("active");
  });
});


$(document).ready(function () {
  $('.clicked, .clickable').on('click', function(){
    $('li').removeClass("clicked");
    if ($(this).hasClass('clickable')){
      $('.content').hide();
      $(this).next().show();
      $(this).removeClass('clickable').addClass('clicked');
      $("#invest__dropdown__pic").addClass('dropdown__pic__invest');
    } else {
      $(this).next().hide();
      $(this).removeClass('clicked').addClass('clickable');
    }
  });
});

$(document).ready(function() {
  $(".navbar__lan__dropdown").click(function() {
    $(".lan__dropdown__content").toggleClass("show");
  });
});


//$("#menuToggle").click(function(){
//   $('body').toggleClass('stop-scrolling');
//});

$("#menuToggle").click(function(){
  if ($(".submenu__list").css('display') == 'block') {
        $('body').addClass('stop-scrolling');
  }else{
        $('body').removeClass('stop-scrolling');
  }

});
