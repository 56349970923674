<template>
  <div>
    <div class="sitemap">
      <div class="container">
        <div class="sitemap__block">
          <div class="sitemap__logo">
            <router-link :to="{ name: 'Home' }">
              <img src="../assets/pic/sitemap__logo.png" />
            </router-link>
          </div>
          <div class="sitemap__content">
            <p>新北市五股區新北產業園區五權五路33號</p>
            <div class="sitmap_tel">
              <p>聯絡電話：02-22993348</p>
              <p>傳真專線：02-22993350</p>
            </div>
          </div>
        </div>
        <div class="sitemap__menu">
          <ol>
            <li class="sitemap__menu-list">
              <router-link :to="{ name:'About'}"
              class="footer-title"
              v-scroll-to="'#About'"
              >關於宏偉</router-link>
              <span class="sitemap__menu__line"></span>
              <ul class="sitemap__manu__list">
                <li><router-link :to="{ name: 'Intro' }"
                    v-scroll-to="'#Intro'" >公司簡介</router-link></li>
                <li><router-link :to="{ name: 'AboutHistory' }"
                      v-scroll-to="'#AboutHistory'" >歷史沿革</router-link></li>
                <li><router-link :to="{ name: 'AboutGlory' }"
                      v-scroll-to="'#AboutGlory'" >宏偉的榮耀</router-link></li>
              </ul>
            </li>
            <li class="sitemap__menu-list">
              <router-link :to="{ name: 'product', params: {ptid: 174, psid: 177}}"
              class="footer-title"
              v-scroll-to="'#product'">
                產品資訊
              </router-link>
              <span class="sitemap__menu__line"></span>
              <ul class="sitemap__manu__list">
                <li v-for="a in ProductFooterLink" :key="a.id">
                  <router-link 
                    :to="{ name: 'product', params: { ptid: a.p1, psid: a.p2}}"
                    v-scroll-to="'#product'"
                    @click="checkReload()">
                  {{a.title}}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="sitemap__menu-list">
              <router-link :to="{name:'Professional'}" v-scroll-to="'#Professional'"
              class="footer-title"
              >專業生產</router-link>
              <span class="sitemap__menu__line"></span>
              <ul class="sitemap__manu__list">
                <li>
                  <router-link :to="{name:'Process'}" v-scroll-to="'#Process'">生產流程</router-link>
                </li>
                <li>
                  <router-link :to="{name:'Promise'}" v-scroll-to="'#Promise'">品質保證</router-link>
                </li>
                <li>
                  <router-link :to="{name:'Quality'}" v-scroll-to="'#Quality'">品質政策</router-link>
                </li>
              </ul>
            </li>
            <li class="sitemap__menu-list">
              <router-link :to="{name:'Service'}" v-scroll-to="'#Service'"
              class="footer-title"
              >客服中心</router-link>
              <span class="sitemap__menu__line"></span>
              <ul class="sitemap__manu__list">
                <li>
                  <router-link :to="{name:'ServiceCenter'}" v-scroll-to="'#ServiceCenter'">我們的服務</router-link>
                </li>
                <li>
                  <router-link :to="{name:'ServiceLocation'}" v-scroll-to="'#ServiceLocation'">服務據點</router-link>
                </li>
                <li>
                  <router-link :to="{name:'ServiceRemoteControl'}" v-scroll-to="'#ServiceRemoteControl'">
                    遠端回報監控系統
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'ServiceExample'}" v-scroll-to="'#ServiceExample'">
                    客戶案例
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'ServiceProductReponsibility'}" v-scroll-to="'#ServiceProductReponsibility'">產品責任險</router-link>
                </li>
              </ul>
            </li>
            <li class="sitemap__menu-list">
              <router-link :to="{name:'Invest', params:{l1:41,l2:160}}"
              class="footer-title" v-scroll-to="'#invest'">
                投資人專區
              </router-link>
              <span class="sitemap__menu__line"></span>
              <ul class="sitemap__manu__list">
                <li v-for="a in InvestFooterLink" :key="a.id">
                  <router-link :to="{name: 'Invest', params: { l1: a.p1, l2: a.p2}}"
                  v-scroll-to="'#invest'">
                    {{a.title}}
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="sitemap__menu-list">
              <router-link :to="{name:'Resource'}" v-scroll-to="'#Resource'"
              class="footer-title"
              >人力資源</router-link>
              <span class="sitemap__menu__line"></span>
              <ul class="sitemap__manu__list">
                <li>
                  <a href="https://www.104.com.tw/jobs/main/" target="_blank">
                        現有職缺
                  </a>
                </li>
                <li>
                  <router-link :to="{name:'ResourceHumanResources'}" v-scroll-to="'#ResourceHumanResources'">人力資源</router-link>
                </li>
                <li>
                  <router-link :to="{name:'ResourceContactUs'}" v-scroll-to="'#ResourceContactUs'">如何到宏偉</router-link>
                </li>
              </ul>
            </li> 
            <li class="sitemap__menu-list">
              <router-link :to="{name:'Contact'}"
              class="footer-title"
              v-scroll-to="'#Contact'"
              >聯絡我們</router-link>
              <span class="sitemap__menu__line"></span>
            </li>
            <li class="sitemap__menu-list">
              <router-link :to="{name:'Report'}"
              class="footer-title"
              v-scroll-to="'#report'"
              >檢舉專區</router-link>
              <span class="sitemap__menu__line"></span>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <span>© 2022 宏偉電機工業股份有限公司</span>
        <span class="footer__br">
          <span>All Rights Reserved</span>
          <!-- ｜
            <a tag="button" data-toggle="modal"               data-target="#terms-of-use">
            使用條款
          </a>｜
          <a data-toggle="modal" data-target="#privacy-policy">隱私權政策</a> -->
        </span>
      </div>
    </div>
      <div class="modal fade" id="terms-of-use" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">使用條款</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              1. 本網站的服務範圍 / 內容
              本網站提供公司簡介 、 公司理念 、 最新推廣及優惠 、 最新動態消息及聯絡天醫的方法 。
              2. 使用條款變更
              天醫保留權利可隨時修改或刪除使用條款而不作另行通知 。 閣下應定期瀏覽本網站 ， 以瞭解對閣下具有約束力的使用條款是否有所修改 ， 因爲使用條款一經修改 ， 而閣下繼續使用本網站 ， 即代表閣下同意受最新版的使用條款約束。<br/>
              3. 網站更新
              天醫保留權利可隨時更新或修改本網站內容或改變本網站的展示方式、實質內容或功用而不作另行通知。
              <br/>
              4. 使用本網站的行為操守<br/>
              閣下使用本網站的條件之一 ，是不得：<br/>
              4.1  擅自入侵、使用、訪問或試圖入侵、使用或訪問我們的伺服器的任何其他部份以及／或任何數據區；<br/>
              4.2  限制或阻止任何其他用戶使用及享用本網站；<br/>
              4.3  利用本網站以任何方式侵犯別人的知識產權；或侵犯本網站上其他用戶之私穩以獲取其身份或任何個人資料；或破壞本網站、物料及／或商品之任何部分；<br/>
              4.4  以任何方式意圖竄改任何網站材料或商品；<br/>
              4.5  張貼或傳送任何違法的、欺詐的、誹謗的、色情的、淫褻的、褻瀆的、不雅的、具有恐嚇成份的、污穢的、敵意的、仇視的或其他不良或不合情理的資料，包括但不限於任何構成或鼓勵傳送的行徑，其會構成刑事罪行者，或產生民事責任者，又或觸犯任何地方、州、國家或涉外法例者，或侵犯其他人士的任何知識產權、專有權利或保密責任者；<br/>
              4.6 張貼或傳送任何廣告、招攬訊息、連鎖信、層壓式推銷計劃、投資機會或計劃或其他不請自來的商業通訊，或作出任何大型的廣告宣傳；<br/>
              4.7  張貼或傳送含有病毒、特洛伊木馬、電腦蟲或其他有害元件的任何資料或軟件；以任何方式干擾本網站的運營，包括但不限於傳輸軟件病毒，或者傳輸專門用來中斷、干擾或改變本網站運營的任何計算機代碼或消息；<br/>
              4.8  利用網站或其他方式收集本網站其他用戶的資料；<br/>
              4.9  未經天醫書面批准﹙除非天醫在本網站中另有訂明，或本司另外給予特許﹚而張貼、發佈、傳送、複製、分發、翻版、翻印、售賣、轉售或以其他方式利用從本網站獲得的任何資料作商業用途；<br/>
              4.10  未經天醫書面批准﹙除非天醫在本網站中另有訂明，或本司另外給予特許﹚而上載、張貼、發佈、傳送、複製或以其他方式分發本網站任何元件或通過本網站取得的任何受版權或其他專利權保障的資料，或創立衍生作品；<br/>
              4.11  以任何方式散佈、公佈、傳播、修訂、展示、銷售本網站之內容或產品或從其中創造衍生性作品或對之加以利用；<br/>
              4.12  未經天醫書面批准，為任何公共或商業目的銷售或更改網站材料，或者複製、重製、再版、上載、下載、登載、傳送、顯示、公開執行或以任何方式發送網站材料，或將材料儲存在檢索系統內，或以其他任何方式使用網站材料。
              閣下對網站材料並沒擁有任何權利，除本使用條款所允許者外，以及／或除天醫另外給予特許外，閣下不得另外使用網站材料。<br/>

              5. 網站材料
              本網站提供有形或無形的材料、數據、內容、圖形、圖像、音樂、標識、圖標按鈕、鏈路、HTML編碼、商標、軟件、照片、圖片、視頻及其他材料與物品﹙下稱為「網站材料」﹚。若天醫同意讓閣下使用本網站，有關特許使用，應是非獨家的、不可轉讓的和有限制的，須受該使用條款所規限。網站材料均受版權法、商標法和其他適用法律的保護。所有網站材料均屬天醫或天醫的網站內容供應商或客戶的專有財產。未經授權擅自使用任何網站材料將違反著作權法、商標法和其他適用法律。若閣下複製任何網站材料，閣下必須在複製件上保留原材料中有關版權、商標、服務標誌以及其他專有權的告示。<br/>

              6. 第三者資料
              網站材料可能含有第三者提供的資料，或天醫從商業資料來源及其他參考途徑或來源獲得的資料。

              如任何網站材料已過時，天醫無須負責，並不會獨立查核第三者或代理人所提供的資料，因此，閣下必須注意網站材料是否可靠準確。對任何網站材料，我們不承擔任何責任。如閣下使用或信賴網站材料，風險由閣下自負。<br/>

              7. 促銷活動<br/>
              天醫將不時在本網站上進行網上促銷活動。若有關促銷活動涉及賺取或累積積分或其他獎賞，則應以天醫根據其記錄所確定的積分點數為最終決定，閣下對天醫之最終決定不得異議。<br/>
              8. 連結網站<br/>
              8.1  本網站或許載有通往由第三方所運營的其他網址或網站的超連結，對該等網站的內容及其處理閣下個人資料的方法，本公司並沒有任何控制權；<br/>
              8.2  對其他網址或網站的超連結，不代表天醫認可或推薦該等網站或當中所提供的資料、產品、廣告或其他材料；<br/>
              8.3  閣下應詳閱該等連結網站之使用條款及細則，明白並同意對該等連結網站所提供的資料是否準確，以及閣下在該等連結網站所蒙受的任何損失或損害，天醫概不負責。<br/>
              9. 嵌入式內容<br/>
              本網站載有的篏入式內容（包括但不限於嵌入式文字、圖像、錄音及視頻），皆直接來自Facebook、Twitter、YouTube、新浪微博或Instagram等第三者網站或社交媒體。嵌入式內容受其所屬網站或網站經營者的條款約束所規管，天醫毋須負責。閣下必須遵守其相關條款，方可使用或連結至該等內容，同時需自行承擔一切後果及責任。<br/>

              10. 知識產權<br/>
              10.1  本網站所存在的一切知識產權，包括内容、產品設計（包括任何及所有相關的附屬材料）均屬天醫及其授權者所有，或已合法地特許給天醫在本網站上使用。由適用法例所授予的一切權利，茲在此予以保留；<br/>
              10.2  儘管閣下可從本網站下載或印制網站材料，僅供作個人非營利性目的的使用，但該等網站材料，仍屬天醫所有，除非本司另有訂明；<br/>
              10.3  閣下同意，天醫可自由使用、披露、採用及修改閣下就本網站向我們提供的一切及任何構思、概念、專門知識、建議、提議、評論及其他通訊及資料﹙「反饋」﹚，而無須向閣下支付任何費用。閣下茲放棄及同意放棄就本司使用、披露、採用及／或修改閣下的任何或一切反饋，提出任何關於代價、費用、專利權費、收費及／或其他付款的一切及任何權利及提出申索。<br/>
              11. 免責聲明<br/>
              11.1  網站材料僅供閣下作一般性參考，或作天醫另外特許用途。對網站材料，天醫概不承擔任何責任。<br/>
              11.2  閣下瀏覽及使用本網站時，應由閣下自負其風險。網站材料是按原貌，沒有增添或刪減而提供。本網站只供閣下個人使用，我們不作出任何種類的明示或默示陳述或保證，包括但不只限於用本網站所宣傳或買賣的產品，或任何適銷保證或適合作任何特定用途。本網站提及任何第三者產品、資訊或服務，並不構成或暗示我們作出任何種類的認可或推薦。<br/>
              11.3  在不限制該等使用條款訂明的免責聲明外，在任何情況下，我們對任何人擅自使用本網站或違反本網站保安程序所招致的任何成本、損害賠償或責任概不負責。<br/>
              11.4  對下述各項，天醫並不給予保證或承擔責任，而閣下確認天醫並無作出陳述或保證：<br/>
              11.4.1  本網站的材料均屬準確、充份、現行或可靠，或網站材料可供的用途超出一般參考用途或天醫另外特許的用途；<br/>
              11.4.2  本網站的材料，並無任何缺陷、錯誤、遺漏、病毒等，可能改變、抹除、增加或損壞閣下的軟件、數據或設備；<br/>
              11.4.3  通過互聯網發送的訊息，不受到攔截、破壞或沒有遺失；<br/>
              11.4.4  本網站的缺陷將可糾正；<br/>
              11.4.5  天醫不作出任何和所有默示性和非明示性的保證、並且不保證本網站或素材的正確性、可靠性、完整性或及時性；<br/>
              11.4.6  天醫對其他網站的內容以及本網站上刊載的或與本網站連接的任何事物，包括但不限於任何謬見、錯誤、遺漏、侵權、譭謗、謊言、或者其他可能是犯法或會引起權利主張或投訴的材料或遺漏不承擔任何責任；<br/>
              11.4.7  使用本網站和連結網站的材料的後果由閣下自負；<br/>
              11.4.8  該等網站可能隨時或定期會有變動，請不時查閱有關的材料；<br/>
              11.4.9  天醫不保證以下事項：本網站目前或今後任何時候毫無錯誤，本網站及其服務器目前或今後任何時候沒有電腦病毒或其他有害機能，任何缺陷會被糾正，或者本網站的運作是及時、安全和不會中斷的；<br/>
              11.4.10  如果由於你使用該等網站材料，導致需要修理或更換設備或數據，天醫不負責任何由此而產生的費用；<br/>
              11.4.11  凡由於使用或無法使用本網站或材料，或任何執行失敗、錯誤、遺漏、中斷、刪除、缺陷、操作或傳送的延誤、電腦病毒、通訊線路失靈、線上通訊的攔截、軟件或硬體問題（包括但不限於丟失數據或相容性問題）、偷竊、網站的破壞或改變所引起的，無論是因使用本網站或向本網 站上載或從本網站下載或出版數據、文字、圖像或其他材料或資料而直接或間接造成的違約、侵權行為、疏忽或任何其他因素所引起的任何種類損害或傷害（包括但不限於意外損害、間接損害、利潤的損失、或者因失去數據或業務被中斷所造成的損害），且無論天醫是否已被告知此種損害的可能性，天醫或在本網站上提及的任何第三方在法律允許的範圍內不應承擔任何責任；<br/>
              11.5  在任何情況下，因使用或未能使用本網站，或本網站或網站材料有任何錯誤或遺漏，以致閣下或任何其他人士蒙受任何直接的、間接的、附帶的、特殊的、懲罰性的或從屬的損害賠償，包括失去業務或利潤，即使我們已獲悉有可能出現該等損害賠償，我們無須向閣下或任何其他人士承擔責任﹙不論是根據侵權法或合約等﹚；<br/>
              11.6  閣下在使用本網站或使用或解釋網站材料上，須行使及完全依賴本身的技能和判斷力。閣下須確保，在使用本網站及網站材料時，符合一切適用的法律規定；
              11.7  該等條款所載的有限責任條文，至法律允許的最大限度上將告適用。<br/>
              12. 阻止使用<br/>
              12.1  雖然我們已盡商業上合理的努力，每日廿四﹙24﹚小時讓用戶使用本網站，但我們不保證閣下一定可使用本網站，而閣下亦確認，我們並未作出陳述或保證本網站必定可供使用。閣下必須就本身的硬件、軟件及設施承擔責任，我們並不保證本網站與閣下的系統兼容。<br/>
              12.2  天醫保留權利暫停本網站以提升或修改本網站；<br/>
              12.3  天醫保留權利限制閣下使用本網站，若我們認為有此合理需要以運作本網站；<br/>
              12.4  若因本網站暫停使用，或閣下被限制使用本網站，以致閣下蒙受任何損失或損害，天醫無須負責。<br/>
              12.5  閣下謹此承認對本網站內容未經授權的使用可能給天醫造成難以彌補的損害，故若發生未經授權的使用，天醫有權在可能獲得的任何普通法下或平衡法下的其他救濟之外申請禁制令。<br/>
              13. 索賠事項<br/>
              如因閣下涉及違反該等使用條款以及／或閣下使用本網站，以致天醫及其母公司的僱員、代理人、董事、承辦商、供應商及其他代表或其他合夥人，承受任何債務、損害賠償、索償、訴訟、成本及支出﹙包括法律費用﹚，閣下同意為彼等抗辯及作出補償，使免遭受損失。如有需要，在任何索賠、訴訟及和解商議中，天醫亦可參與抗辯。未得天醫書面批准，閣下不得作出任何可能對本司的權利或責任構成不利影響的和解。天醫保留權利，經通知閣下後，由本司自費獨自抗辯及控制任何索償或訴訟。閣下謹此同意就閣下對本網站之任何材料的任何可能未經授權的使用向天醫作出賠償，爲天醫進行抗辯並使天醫不受其之影響。<br/>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn" data-dismiss="modal">Close</button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="modal fade" id="privacy-policy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">隱私權政策</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              一、隱私權保護政策的適用範圍<br/>
                隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。<br/>
                二、個人資料的蒐集、處理及利用方式<br/>
                當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
                本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。
                於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
                為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。<br/>
                三、資料之保護<br/>
                只由經過授權的人員才能接觸您的個人資料，如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。<br/>
                四、網站對外的相關連結<br/>
                本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。<br/>

                五、與第三人共用個人資料之政策<br/>
                本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。<br/>

                前項但書之情形包括不限於：<br/>

                經由您書面同意。
                法律明文規定。
                為免除您生命、身體、自由或財產上之危險。
                與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
                當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                有利於您的權益。
                本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
                六、Cookie之使用
                為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。<br/>

                七、隱私權保護政策之修正<br/>
                本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。<br/>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn" data-dismiss="modal">Close</button>
            </div> -->
          </div>
        </div>
      </div>    
  </div>
</template>
<script>
import { apiProductMenu, apiInvestMenu } from "@/api/pages.js";
export default {
  namd: "Footer",
  data(){
    return{
      ProductMenu:[],
      prodType:[],
      prodSerial:[],
      ProductFooterLink:[],
      InvestMenu:[],
      investLevel1:[],
      investLevel2:[],
      InvestFooterLink:[]
    }
  },
  created(){
    this.getData()
  },
  mounted(){
  },
  methods: {
    async getData(){
      this.location = location.href
      const res = await apiProductMenu();
      const { prodType ,prodSerial} = res.data
      this.ProductMenu = res.data;
      this.prodSerial = prodSerial
      this.prodType = prodType
      const res1 = await apiInvestMenu();
      const { investLevel1, investLevel2 } = res1.data;
      this.investLevel1 = investLevel1
      this.investLevel2 = investLevel2
      // console.log('this.InvestMenu:', this.InvestMenu)
      // console.log('this.investLevel1:',this.investLevel1)
      // console.log('this.investLevel2:',this.investLevel2)


      this.prodType.forEach(element => {
        const p2 = this.prodSerial.find(x => {
          return x.parent_id === element.id
        })
        const tmp = {
          title: element.title,
          id: element.id,
          p1: element.id,
          p2: p2.id
        }
        this.ProductFooterLink.push(tmp)
        // console.log(tmp)
        // console.log('this.footerLink',this.ProductFooterLink)
      });
      
      this.investLevel1.forEach(element => {
        const p2 = this.investLevel2.find(x => {
          return x.parent_id === element.id
        })
        const tmp = {
          title: element.title,
          id: element.id,
          p1: element.id,
          p2: p2.id
        }
        this.InvestFooterLink.push(tmp)
        // console.log(tmp)
        // console.log('this.footerLink',this.InvestFooterLink)
      })
    }
  },
}


</script>
<style lang="css" scoped>
  .footer a:hover{
    cursor: pointer;
  }
  a:hover{
    color:white;
    text-decoration: none;
    font-weight: 900;
  }
  .modal-dialog{
      max-height: 80%;
      max-width: 90%;
      margin: auto;
      margin-top: 45px;
  }
  @media screen and (min-width: 768px){
    .modal-dialog {
      max-width: 70%;
      max-height: 500px;
    }  
  }
  @media screen and (min-width: 1200px){
    .modal-dialog {
      margin-top: 20vh; 
    }  
  }
</style>